<template>
  <button
    class="bg-black/[0.16] w-12 h-12 rounded-full !inline-flex items-center justify-center hover:bg-black/[0.34] hover:border hover:border-white/[0.20] disabled:opacity-30 disabled:hover:bg-black/[0.16] disabled:hover:border-none transition-opacity"
    :class="`slider-button-${$props.direction || 'left'}`"
    @click.stop="$emit('click')"
    :aria-label="
      t(direction === 'left' ? 'general.previousSlide' : 'general.nextSlide')
    "
  >
    <span
      class="bg-white w-8 h-8 rounded-full inline-flex items-center justify-center"
    >
      <IconChevron :class="computedDirectionClassName" />
    </span>
  </button>
</template>

<script setup lang="ts">
import { IconChevron } from "@/components/atoms/Icon";
import { computed } from "vue";
import { useSafeI18n } from "@/composables/useSafeI18n";
type IconDirection = "left" | "right";

const props = withDefaults(
  defineProps<{
    direction?: IconDirection;
  }>(),
  {
    direction: "left",
  },
);

defineEmits(["click"]);

const { t } = useSafeI18n();

const computedDirectionClassName = computed(() => {
  switch (props.direction) {
    case "left":
      return "rotate-icon-left";
    case "right":
      return "rotate-icon-right";
    default:
      return "rotate-icon-left";
  }
});
</script>
